import React from 'react';
import '../../assets/styles/business.css';
import Grid from '@mui/material/Grid';
import { BrowserView, MobileView } from 'react-device-detect';


const BusinessProfileCardComponent = (props) => {
    return (
        <Grid item xs={12} md={12}>
            <div className="main-card-Business">

                <img className='Profile-Picture-Business' src={props?.picture} alt="" />

            </div>
        </Grid>
    );
}

export default BusinessProfileCardComponent;