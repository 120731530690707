import React from 'react';

import * as mime from 'react-native-mime-types';
import VCard from 'vcard-creator';
import Grid from '@mui/material/Grid';
import { doc, updateDoc, } from 'firebase/firestore';

import IconAddressBook from "../../assets/icons/IconAddressBook.js";

import { db } from '../../config/firebase.js';
import '../../assets/styles/home.css';

const AddPhoneBookCardCompanent = (props) => {

    const userVCard = new VCard();
    //userVCard.addPhoto(props?.picture);
    userVCard.addLogoURL(props?.picture);
    userVCard.addPhotoURL(props?.picture);
    userVCard.addName(props?.surName, props?.name);
    userVCard.addCompany(props?.company, props?.position);
    userVCard.addPhoneNumber(props?.phone, 'WORK');
    userVCard.addAddress(props?.address);
    props?.contactData?.map((list) => (
        userVCard.addSocial(list.url, list.name, list.value)
    ))
    props?.contactData?.map((list2) => {
        if (list2.name == "mail") {
            userVCard.addEmail(list2.url.replace("mailto:", ""));
        }
    })




    console.log("VCard: " + props?.contactData?.toString());

    userVCard.addURL(props?.url);
    userVCard.setFilename(props?.fullName);
    userVCard.addNote(props?.about);
    userVCard.setFormat('vcard');
    mime.lookup('vcf');
    mime.extension('text/x-vcard');

    const downloadVfcFile = async () => {
        const docRefProfileVfc = doc(db, "profiles", props?.profileId);
        var addContactCountInt = props?.addContactCount + 1;
        await updateDoc(docRefProfileVfc, { addContactCount: addContactCountInt });
        const element = document.createElement("a");
        const file = new Blob([userVCard.toString()],
            { type: 'text/x-vcard;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = props?.name + " " + props?.surName + " " + props?.phone + ".vcf";
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Grid item xs={4} md={4}>
            <a onClick={downloadVfcFile}>
                <div style={{
                    color: 'white',
                    backgroundColor: '#2D2D2D',
                    padding: '10px 0',
                    borderRadius: '10px',
                    textAlign: 'center',
                }}>
                    <center><IconAddressBook style={{
                        color: 'white',
                        padding: '10px 0',
                        width: 60,
                        height: 60,
                        textAlign: 'center',
                    }} /></center>
                    <p style={{ fontSize: "10pt", padding: '5px 0', }} >
                        {props?.countryCode === "tr" ? (
                            "Rehbere Ekle"
                        ) : (
                            "Add Phone Book"
                        )}
                    </p>
                </div>
            </a>
        </Grid>
    );
}

export default AddPhoneBookCardCompanent;