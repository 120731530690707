import React from 'react';
import '../../assets/styles/home.css';
import Grid from '@mui/material/Grid';

import IconAmazon from "../../assets/icons/IconAmazon.js";
import IconBeetinq from "../../assets/icons/IconBeetinq.js";

import IconDiscord from "../../assets/icons/IconDiscord.js";
import IconEnvelope from "../../assets/icons/IconEnvelope.js";
import IconFacebook from "../../assets/icons/IconFacebook.js";
import IconInstagram from "../../assets/icons/IconInstagram.js";
import IconLinkedin from "../../assets/icons/IconLinkedin.js";
import IconMapPin from "../../assets/icons/IconMapPin.js";
import IconTelegram from "../../assets/icons/IconTelegram.js";
import IconTwitch from "../../assets/icons/IconTwitch.js";
import IconTwitter from "../../assets/icons/IconTwitter.js";
import IconWeb from "../../assets/icons/IconWeb.js";
import IconWhatsapp from "../../assets/icons/IconWhatsapp.js";
import IconYoutube from "../../assets/icons/IconYoutube.js";
import IconPhone from "../../assets/icons/IconPhone.js";
import IconAddressBook from "../../assets/icons/IconAddressBook.js";

//PAZARYERLERİ
import IconBitcoin from "../../assets/icons/IconBitcoin.js";
import IconTrendyol from "../../assets/icons/IconTrendyol.js";
import IconHepsiburada from "../../assets/icons/IconHepsiburada.js";
import IconN11 from "../../assets/icons/IconN11.js";


//BANKALAR
import IconAkbank from "../../assets/icons/IconAkbank.js";
import IconAlbaraka from "../../assets/icons/IconAlbaraka.js";
import IconGaranti from "../../assets/icons/IconGaranti.js";
import IconHalkbank from "../../assets/icons/IconHalkbank.js";
import IconIng from "../../assets/icons/IconIng.js";
import IconIsbank from "../../assets/icons/IconIsbank.js";
import IconKuveytturk from "../../assets/icons/IconKuveytturk.js";
import IconQnbfinansbank from "../../assets/icons/IconQnbfinansbank.js";
import IconTeb from "../../assets/icons/IconTeb.js";
import IconTurkiyefinans from "../../assets/icons/IconTurkiyefinans.js";
import IconVakifbank from "../../assets/icons/IconVakifbank.js";
import IconYapikredi from "../../assets/icons/IconYapikredi.js";
import IconZiraat from "../../assets/icons/IconZiraat.js";

const IconCardCompanent = (props) => {
    return (
        <Grid item xs={4} md={4}>
            <a href={`${props.url}`} target="_blank">
                <div style={{
                    color: 'white',
                    backgroundColor: '#2D2D2D',
                    padding: '10px 0',
                    borderRadius: '10px',
                    textAlign: 'center',
                }}>
                    {svgicon(props.name)}
                    {props.countryCode == "tr" ? (
                        <p style={{ fontSize: "10pt", padding: '5px 0', }} >{`${socialnamesTr(props.name)}`}</p>
                    ) : (
                        <p style={{ fontSize: "10pt", padding: '5px 0', }} >{`${socialnamesEn(props.name)}`}</p>
                    )}
                </div>
            </a>
        </Grid>
    );
}

export default IconCardCompanent;



function svgicon(state) {
    switch (state) {

        //PAZARYERLERİ
        case 'amazon':
            return <center><IconAmazon style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'trendyol':
            return <center><IconTrendyol style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'hepsiburada':
            return <center><IconHepsiburada style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'n11':
            return <center><IconN11 style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>

        //BANKALAR    
        case 'akbank':
            return <center><IconAkbank style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'albaraka':
            return <center><IconAlbaraka style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'garanti':
            return <center><IconGaranti style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'halkbank':
            return <center><IconHalkbank style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'ing':
            return <center><IconIng style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'işbank':
            return <center><IconIsbank style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'kuveyttürk':
            return <center><IconKuveytturk style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'qnbfinansbank':
            return <center><IconQnbfinansbank style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'teb':
            return <center><IconTeb style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'türkiyefinans':
            return <center><IconTurkiyefinans style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'vakıfbank':
            return <center><IconVakifbank style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'yapıkredi':
            return <center><IconYapikredi style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'ziraat':
            return <center><IconZiraat style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>


        //SOSYAL MEDYA    
        case 'linkedin':
            return <center><IconLinkedin style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'instagram':
            return <center><IconInstagram style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'facebook':
            return <center><IconFacebook style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'beetinq':
            return <center><IconBeetinq style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'twitter':
            return <center><IconTwitter style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'youtube':
            return <center><IconYoutube style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'discord':
            return <center><IconDiscord style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'twitch':
            return <center><IconTwitch style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'telegram':
            return <center><IconTelegram style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'whatsapp':
            return <center><IconWhatsapp style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'website':
            return <center><IconWeb style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'mail':
            return <center><IconEnvelope style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'location':
            return <center><IconMapPin style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'phone':
            return <center><IconPhone style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        case 'addressbook':
            return <center><IconAddressBook style={{
                color: 'white',
                padding: '10px 0',
                width: 60,
                height: 60,
                textAlign: 'center',
            }} /></center>
        default:
            return ""
    }
}




function socialnames(state) {
    switch (state) {
        case 'phone':
            return "Telefon"
        case 'trendyol':
            return "Trendyol"
        case 'hepsiburada':
            return "Hepsiburada"
        case 'akbank':
            return "Akbank"
        case 'albaraka':
            return "Albaraka"
        case 'garanti':
            return "Garanti"
        case 'halkbank':
            return "Halkbank"
        case 'ing':
            return "İNG"
        case 'işbank':
            return "Türkiye İş Bankası"
        case 'kuveyttürk':
            return "KuveytTürk"
        case 'qnbfinansbank':
            return "Qnb Finansbank"
        case 'teb':
            return "Türkiye Ekonomi Bankası"
        case 'türkiyefinans':
            return "Türkiye Finans"
        case 'vakıfbank':
            return "Vakıfbank"
        case 'yapıkredi':
            return "Yapı Kredi"
        case 'ziraat':
            return "Ziraat Bankası"
        case 'n11':
            return "n11"
        case 'linkedin':
            return "Linkedin"
        case 'instagram':
            return "Instagram"
        case 'facebook':
            return "Facebook"
        case 'twitter':
            return "Twitter"
        case 'youtube':
            return "Youtube"
        case 'discord':
            return "Discord"
        case 'twitch':
            return "Twitch"
        case 'telegram':
            return "Telegram"
        case 'whatsapp':
            return "WhatsApp"
        case 'website':
            return "Web"
        case 'mail':
            return "E-Mail"
        case 'location':
            return "Adres"
        case 'beetinq':
            return "beetinq"
        case 'amazon':
            return "Amazon"
        case 'bitcoin':
            return "Bitcoin"
        default:
            return ""
    }
}


function socialnamesEn(state) {
    switch (state) {
        case 'phone':
            return "Phone"
        case 'trendyol':
            return "Trendyol"
        case 'hepsiburada':
            return "Hepsiburada"
        case 'akbank':
            return "Akbank"
        case 'albaraka':
            return "Albaraka"
        case 'garanti':
            return "Garanti"
        case 'halkbank':
            return "Halkbank"
        case 'ing':
            return "ING"
        case 'işbank':
            return "Türkiye İş Bank"
        case 'kuveyttürk':
            return "KuveytTürk"
        case 'qnbfinansbank':
            return "Qnb Finansbank"
        case 'teb':
            return "Türkiye Ekonomi Bank"
        case 'türkiyefinans':
            return "Türkiye Finans"
        case 'vakıfbank':
            return "Vakıfbank"
        case 'yapıkredi':
            return "Yapı Kredi"
        case 'ziraat':
            return "Ziraat Bank"
        case 'n11':
            return "n11"
        case 'linkedin':
            return "Linkedin"
        case 'instagram':
            return "Instagram"
        case 'facebook':
            return "Facebook"
        case 'twitter':
            return "Twitter"
        case 'youtube':
            return "Youtube"
        case 'discord':
            return "Discord"
        case 'twitch':
            return "Twitch"
        case 'telegram':
            return "Telegram"
        case 'whatsapp':
            return "WhatsApp"
        case 'website':
            return "Web"
        case 'mail':
            return "E-Mail"
        case 'location':
            return "Adres"
        case 'beetinq':
            return "beetinq"
        case 'amazon':
            return "Amazon"
        case 'bitcoin':
            return "Bitcoin"
        default:
            return ""
    }
}

function socialnamesTr(state) {
    switch (state) {
        case 'phone':
            return "Telefon"
        case 'trendyol':
            return "Trendyol"
        case 'hepsiburada':
            return "Hepsiburada"
        case 'akbank':
            return "Akbank"
        case 'albaraka':
            return "Albaraka"
        case 'garanti':
            return "Garanti"
        case 'halkbank':
            return "Halkbank"
        case 'ing':
            return "İNG"
        case 'işbank':
            return "Türkiye İş Bankası"
        case 'kuveyttürk':
            return "KuveytTürk"
        case 'qnbfinansbank':
            return "Qnb Finansbank"
        case 'teb':
            return "Türkiye Ekonomi Bankası"
        case 'türkiyefinans':
            return "Türkiye Finans"
        case 'vakıfbank':
            return "Vakıfbank"
        case 'yapıkredi':
            return "Yapı Kredi"
        case 'ziraat':
            return "Ziraat Bankası"
        case 'n11':
            return "n11"
        case 'linkedin':
            return "Linkedin"
        case 'instagram':
            return "Instagram"
        case 'facebook':
            return "Facebook"
        case 'twitter':
            return "Twitter"
        case 'youtube':
            return "Youtube"
        case 'discord':
            return "Discord"
        case 'twitch':
            return "Twitch"
        case 'telegram':
            return "Telegram"
        case 'whatsapp':
            return "WhatsApp"
        case 'website':
            return "Web"
        case 'mail':
            return "E-Mail"
        case 'location':
            return "Adres"
        case 'beetinq':
            return "beetinq"
        case 'amazon':
            return "Amazon"
        case 'bitcoin':
            return "Bitcoin"
        default:
            return ""
    }
}