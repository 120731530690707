import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom"
import Home from "./pages/home/Home"
import WebPage from "./pages/web"


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:cardId" element={<Home />} />
        <Route path="/web" element={<WebPage />} />
      </Routes>
    </Router>
  );
}

export default App;
