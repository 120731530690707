import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB6B6IFWrgJhI_I1s4aZ8qz485EM1v-fnU",
    authDomain: "cardnero-app.firebaseapp.com",
    projectId: "cardnero-app",
    storageBucket: "cardnero-app.appspot.com",
    messagingSenderId: "1034974160606",
    appId: "1:1034974160606:web:06ce99bfc9c5e38dbd044f",
    measurementId: "G-FFNPB1RF8X"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

