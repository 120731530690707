import React from 'react';
import '../../assets/styles/home.css';
import AppBar from '@mui/material/AppBar';

const AppBarCompanent = (props) => {
    return (
        <AppBar
            position="absolute"
            elevation={0}
            sx={{
                position: 'relative',
                background: '#171C1F',
            }}>
            <br></br>
        </AppBar>
    );
}

export default AppBarCompanent;

