import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom"
import { query, where, getDocs, doc, getDoc, updateDoc, collection } from 'firebase/firestore';

import { db } from '../../config/firebase';
import '../../assets/styles/home.css';

import UserProfileCardComponent from '../../components/cards/userProfileCardComponent.js';
import BusinessProfileCardComponent from '../../components/cards/businessProfileCardComponent.js';
import IconCardCompanent from '../../components/cards/iconCardCompanent.js';
import AddPhoneBookCardCompanent from '../../components/cards/addPhoneBookCardCompanent.js';
import FooterCompanent from '../../components/footer/footer.js';
import AppBarCompanent from '../../components/header/appbar.js';
import FormModalCompanent from '../../components/modals/formModalCompanent.js';
import BookingModalCompanent from '../../components/modals/bookingModalCompanent.js';
import ThemeDark from '../../components/theme/themeDark.js';
import BusinessUsersCompanent from '../../components/businessusers/businessUsers.js';



const Home = () => {

    const [profileData, setProfileData] = React.useState([]);
    const [cardData, setCardData] = React.useState([]);
    const [contactData, setContactData] = React.useState([]);
    let { cardId } = useParams();
    let countryTrCode = "tr";

    useEffect(() => {
        getCardInfo();
    }, []);

    const getCardInfo = async (e) => {
        const docRefCard = doc(db, "cards", cardId);
        const docSnapCard = await getDoc(docRefCard);
        if (docSnapCard.exists()) {
            setCardData(docSnapCard.data());
            const docRefProfile = doc(db, "profiles", docSnapCard.data().profileId);
            const docSnapProfile = await getDoc(docRefProfile);
            if (docSnapProfile.exists()) {
                console.log("Document data:", docSnapProfile.data());
                setProfileData(docSnapProfile.data());
                //Profile State 0 - Bu profil Kapalıdır
                var viewCountInt = docSnapProfile.data().viewCount + 1;
                await updateDoc(docRefProfile, { viewCount: viewCountInt });
            } else {
                console.log("No such document!");
            }
            const collectionRefContact = collection(db, 'info')
            const qContact = query(collectionRefContact, where("profileId", "==", docSnapCard.data().profileId))
            const doc_refs = await getDocs(qContact);
            const resContact = [];
            doc_refs.forEach(contact => {
                resContact.push({
                    id: contact.id,
                    name: contact.data().name,
                    type: contact.data().type,
                    url: contact.data().url,
                    state: contact.data().state,
                })
            });
            setContactData(resContact);
        } else {
            console.log("No such document!");
        }
    }

    return (
        <ThemeProvider theme={ThemeDark}>

            <CssBaseline />

            <AppBarCompanent />

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }} style={{ background: '#171C1F' }}>

                <React.Fragment>

                    <Grid container spacing={3} justifyContent="center" alignItems="center">


                        {profileData?.isCompany ? (
                            <BusinessProfileCardComponent
                                picture={profileData?.picture}
                                name={profileData?.name}
                                surName={profileData?.surName}
                                position={profileData?.position}
                                company={profileData?.company}
                            />
                        ) : (
                            <UserProfileCardComponent
                                picture={profileData?.picture}
                                name={profileData?.name}
                                surName={profileData?.surName}
                                position={profileData?.position}
                                company={profileData?.company}
                            />
                        )}



                        {profileData?.about !== "" ? (
                            <Grid item xs={12} md={12}>
                                <div style={{
                                    color: 'white',
                                    backgroundColor: '#2D2D2D',
                                    padding: '15px 0',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                }}>
                                    <p style={{ fontSize: "12pt", padding: '5px 0', }} >{profileData?.about}</p>
                                </div>
                            </Grid>
                        ) : (<div></div>)}

                        <Grid item xs={12} md={12}></Grid>

                    </Grid>


                    {profileData?.isCompany ? (
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                            {profileData?.users.map(profilid => (
                                <BusinessUsersCompanent profilid={profilid} />
                            ))}
                            <Grid item xs={12} md={12}></Grid>
                        </Grid>
                    ) : (<div></div>)}

                    <Grid container spacing={3} justifyContent="center" alignItems="center">

                        {!profileData?.isCompany ? (
                            <AddPhoneBookCardCompanent
                                surName={profileData?.surName}
                                name={profileData?.name}
                                company={profileData?.company}
                                position={profileData?.position}
                                mail={profileData?.mail}
                                phone={profileData?.phone}
                                address={profileData?.address}
                                contactData={contactData}
                                url={profileData?.url}
                                about={profileData?.about}
                                profileId={profileData?.profileId}
                                addContactCount={profileData?.addContactCount}
                                countryCode = {profileData?.countryCode}
                            />
                        ) : (<div></div>)}


                        {!profileData?.isCompany ? (
                            <IconCardCompanent
                                url={"tel:" + profileData?.phone}
                                name="phone"
                                countryCode = {profileData?.countryCode}
                            />
                        ) : (<div></div>)}


                        {contactData?.map((list) => (
                            list.type === "contact" ? (
                                <IconCardCompanent
                                    url={`${list.url}`}
                                    name={list.name}
                                    countryCode = {profileData?.countryCode}
                                />
                            ) : (<div></div>)
                        ))}

                        {contactData?.map((list) => (
                            list.type === "social" ? (
                                <IconCardCompanent
                                    url={`${list.url}`}
                                    name={list.name}
                                    countryCode = {profileData?.countryCode}
                                />
                            ) : (<div></div>)
                        ))}

                        {contactData?.map((list) => (
                            list.type === "market" ? (
                                <IconCardCompanent
                                    url={`${list.url}`}
                                    name={list.name}
                                    countryCode = {profileData?.countryCode}
                                />
                            ) : (<div></div>)
                        ))}


                        {contactData?.map((list) => (
                            list.type === "bank" ? (
                                <IconCardCompanent
                                    url={`${list.url}`}
                                    name={list.name}
                                    countryCode = {profileData?.countryCode}
                                />
                            ) : (<div></div>)
                        ))}

                        <Grid item xs={12} md={12}>
                            <BookingModalCompanent
                                namesurname={profileData?.name + " " + profileData?.surName}
                                countryTrCode={countryTrCode}
                                countryCode={profileData?.countryCode}
                                userId={profileData?.userId}
                                cardId={cardId}
                                profileId={profileData?.profileId}
                                bookingminute="30"
                            //bookingminute={profileData?.bookingminute}
                            />
                        </Grid>


                        <Grid item xs={12} md={12}>
                            <FormModalCompanent
                                countryTrCode={countryTrCode}
                                countryCode={profileData?.countryCode}
                                userId={profileData?.userId}
                                cardId={cardId}
                                profileId={profileData?.profileId}
                            />
                        </Grid>
                    </Grid>


                    <Grid item xs={12} md={12}><br></br></Grid>





                    <FooterCompanent />

                </React.Fragment>

            </Container>

        </ThemeProvider >
    );
}


export default Home;