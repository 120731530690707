import React from 'react';
import Grid from '@mui/material/Grid';


const WebPage = (props) => {
    return (
        <Grid item xs={4} md={4}>
            <a href={`https://cardnero.com`}>
                <center>
                    WEB PAGE
                </center>
            </a>
        </Grid>
    );
}

export default WebPage;

