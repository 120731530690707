import React from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react';
import { getDocs, doc, getDoc, addDoc, updateDoc, collection, Timestamp } from 'firebase/firestore';


import { db } from '../../config/firebase';
import '../../assets/styles/home.css';

const FormModalCompanent = (props) => {

    const [activeStep, setActiveStep] = React.useState(0);

    const [formOpen, setFormOpen] = React.useState(false);
    const handleFormOpen = () => {
        setFormOpen(true);
        setActiveStep(0);
    };
    const handleFormClose = () => setFormOpen(false);


    const [SettingFormState, setSettingFormState] = React.useState(false);
    const [SettingFormTitle, setSettingFormTitle] = React.useState("Teklif İste");

    //////////////////////////// Form Data Hesapla////////////////////////////
    const getSettingFormData = async (e) => {
        const docRef = doc(db, "profiles/" + props?.profileId + "/setting/", "1");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setSettingFormState(docSnap.data().state);
            setSettingFormTitle(docSnap.data().title);
        } else {
            console.log("No such document!");
        }
        console.log("getSettingFormData çalıştı");
    }

    getSettingFormData();


    //////////////////////FORM///////////////////////////////////

    const [controllerFormName, setControllerFormName] = React.useState('');
    const hCsetControllerFormName = (event) => {
        setControllerFormName(event.target.value);
    };

    const [controllerFormPhone, setControllerFormPhone] = React.useState('');
    const hCsetControllerFormPhone = (event) => {
        if (Array.from(event.target.value)[0] == "5") {
            setControllerFormPhone("+90" + event.target.value);
        } else if (Array.from(event.target.value)[0] == "0") {
            setControllerFormPhone("+9" + event.target.value);
        } else if (Array.from(event.target.value)[0] == "9") {
            setControllerFormPhone("+" + event.target.value);
        } else {
            setControllerFormPhone(event.target.value);
        }
    };

    const [controllerFormMail, setControllerFormMail] = React.useState('');
    const hCsetControllerFormMail = (event) => {
        setControllerFormMail(event.target.value);
    };

    const [controllerFormSubject, setControllerFormSubject] = React.useState('');
    const hCsetControllerFormSubject = (event) => {
        setControllerFormSubject(event.target.value);
    };

    const [controllerFormDetail, setControllerFormDetail] = React.useState('');
    const hCsetControllerFormDetail = (event) => {
        setControllerFormDetail(event.target.value);
    };


    const handleFormSubmit = useCallback(async (e) => {
        const current = new Date();
        const datenow = `${current.getFullYear()}/${current.getMonth()}/${current.getDate()} ${current.getHours()}:${current.getMinutes()}`;
        const docRef = await addDoc(collection(db, 'forms'), {
            formId: "",
            userId: props?.userId,
            cardId: props?.cardId,
            profileId: props?.profileId,
            fullName: controllerFormName,
            phone: controllerFormPhone,
            mail: controllerFormMail,
            address: "",
            file: [],
            subject: controllerFormSubject,
            detail: controllerFormDetail,
            title: "Form Başlığı",
            type: "type",
            createDate: Timestamp.fromDate(new Date()),
            startDate: Timestamp.fromDate(new Date()),
            endDate: Timestamp.fromDate(new Date()),
            status: true,
            state: true,
        });
        console.log("DocId: " + docRef.id);
        await updateDoc(doc(db, "forms", docRef.id), {
            formId: docRef.id
        });
        setActiveStep(1);
    });


    return (

        <div>

            {SettingFormState ? (
                <div>
                    <Grid item xs={12} md={12}>
                        <div onClick={handleFormOpen} style={{
                            color: 'white',
                            backgroundColor: '#2D2D2D',
                            padding: '15px 0',
                            borderRadius: '10px',
                            textAlign: 'center',
                        }}>
                            <p style={{ fontSize: "15pt", padding: '5px 0', }} >
                                {props?.countryCode === props?.countryTrCode ? (
                                    "Teklif İste"
                                ) : (
                                    "Quote Request"
                                )}
                            </p>
                        </div>
                    </Grid>
                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        open={formOpen}
                        onClose={handleFormClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                TransitionComponent: Fade,
                            },

                        }}
                    >
                        <Fade in={formOpen}>
                            <Box sx={style}>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={10}>
                                        <Typography id="spring-modal-title" variant="h6" component="h2">
                                            {props?.countryCode === props?.countryTrCode ? (
                                                "Teklif İste"
                                            ) : (
                                                "Quote Request"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} textAlign={"right"}>
                                        <Button onClick={handleFormClose}>
                                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#2D2D2D', fontSize: "18pt", }} />
                                        </Button>
                                    </Grid>

                                    {activeStep === 0 ? (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="firstName"
                                                    name="firstName"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Ad Soyad"
                                                    ) : (
                                                        "Name Surname"
                                                    )}
                                                    value={controllerFormName}
                                                    onChange={hCsetControllerFormName}
                                                    fullWidth
                                                    autoComplete="given-name" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="firstName"
                                                    name="firstName"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Telefon"
                                                    ) : (
                                                        "Phone"
                                                    )}
                                                    value={controllerFormPhone}
                                                    onChange={hCsetControllerFormPhone}
                                                    fullWidth
                                                    autoComplete="given-name" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="firstName"
                                                    name="firstName"
                                                    label="E-Mail"
                                                    value={controllerFormMail}
                                                    onChange={hCsetControllerFormMail}
                                                    fullWidth
                                                    autoComplete="given-name" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="firstName"
                                                    name="firstName"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Konu"
                                                    ) : (
                                                        "Subject"
                                                    )}
                                                    value={controllerFormSubject}
                                                    onChange={hCsetControllerFormSubject}
                                                    fullWidth
                                                    autoComplete="given-name" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField

                                                    id="lastName"
                                                    name="lastName"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Detay"
                                                    ) : (
                                                        "Detail"
                                                    )}
                                                    maxRows={5}
                                                    minRows={5}
                                                    value={controllerFormDetail}
                                                    onChange={hCsetControllerFormDetail}
                                                    multiline
                                                    fullWidth
                                                    autoComplete="family-name" />

                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                < Button
                                                    variant="contained"
                                                    onClick={handleFormSubmit}
                                                >{props?.countryCode === props?.countryTrCode ? (
                                                    "Gönder"
                                                ) : (
                                                    "Send"
                                                )}</Button>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12}>
                                                <center>
                                                    <br />
                                                    <Typography id="spring-modal-title" variant="h6" component="h3">
                                                        {props?.countryCode === props?.countryTrCode ? (
                                                            "Talebiniz alınmıştır."
                                                        ) : (
                                                            "Your request has been received."
                                                        )}
                                                    </Typography>
                                                    <Typography id="spring-modal-title" variant="h6" component="h3">
                                                        {props?.countryCode === props?.countryTrCode ? (
                                                            "En kısa sürede dönüş yapılacaktır."
                                                        ) : (
                                                            "Return will be made as soon as possible."
                                                        )}
                                                    </Typography>
                                                    <br /><br />
                                                    <Grid item xs={12} sm={12}>
                                                        < Button
                                                            variant="contained"
                                                            onClick={handleFormClose}
                                                        >{props?.countryCode === props?.countryTrCode ? (
                                                            "Kapat"
                                                        ) : (
                                                            "Close"
                                                        )}</Button>
                                                    </Grid>
                                                </center>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Box>
                        </Fade>
                    </Modal>
                </div >
            ) : (<div></div>)}
        </div >
    );
}

export default FormModalCompanent;


const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '5%',
    left: '5%',
    bottom: '5%',
    right: '5%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "hidden",
    overflowY: "scroll",
};
