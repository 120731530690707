// icon:address-book | Teeny Icons https://teenyicons.com/ | Anja van Staden
import * as React from "react";

function IconAddressBook(props) {
    return (
        <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M3.5 0A1.5 1.5 0 002 1.5V4H1v1h1v5H1v1h1v2.5A1.5 1.5 0 003.5 15h9a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0012.5 0h-9zM6 5a2 2 0 114 0 2 2 0 01-4 0zm-1 6a3 3 0 116 0v1H5v-1z"
                clipRule="evenodd"
            />
        </svg>
    );
}

export default IconAddressBook;