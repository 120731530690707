import { createTheme } from '@mui/material/styles';

const ThemeDark = createTheme({
    palette: {
        background: {
            default: "#171C1F",
            marginTop: '1em'
        }
    },
});

export default ThemeDark;
