import React from 'react';
import '../../assets/styles/business.css';
import Grid from '@mui/material/Grid';
import { BrowserView, MobileView } from 'react-device-detect';


const UserMiniCardCompanent = (props) => {
    return (
        < Grid item xs={4} md={4} >
            <a href={props?.url}>
                <div className="main-card-Company">
                    <img className='Profile-Picture-Company' src={props?.picture} />
                    <div className="card-title-company">
                        <BrowserView>
                            <div className="name-surname-desktop-Company">
                                {props?.namesurname}
                            </div>
                            <div className="person-title-desktop-Company">
                                {props?.position}
                            </div>
                        </BrowserView>
                        <MobileView><div className="name-surname-Company">
                            {props?.namesurname}
                        </div>
                            <div className="person-title-Company">
                                {props?.position}
                            </div>
                        </MobileView>
                    </div>
                </div>
            </a>
        </Grid >
    );
}

export default UserMiniCardCompanent;