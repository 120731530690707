import React from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { tr,enUS } from 'date-fns/locale';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { ScheduleMeeting } from 'react-schedule-meeting';
import { timeSlotDifference } from 'react-schedule-meeting';
import { useCallback, useEffect } from 'react';
import ICalendarLink from "react-icalendar-link";
import { query, where, getDocs, doc, getDoc, addDoc, updateDoc, collection, Timestamp } from 'firebase/firestore';

import { db } from '../../config/firebase';
import '../../assets/styles/home.css';

const BookingModalCompanent = (props) => {

    const [activeStep, setActiveStep] = React.useState(0);


    const [bookingOpen, setBookingOpen] = React.useState(false);
    const handleBookingOpen = () => {
        setBookingOpen(true);
        setActiveStep(0);
    };
    const handleBookingClose = () => setBookingOpen(false);


    ////////////////////////////// RANDEVU ZAMANLARI HESAPLAMA////////////////////////////////////////////////////////

    const availableTimeSlots = [];
    const unavailableTimeSlots = [];
    const [SettingBookingState, setSettingBookingState] = React.useState(false);
    const [SettingData, setSettingData] = React.useState([]);
    const [SettingTimes, setSettingTimes] = React.useState([]);
    const [SettingDays, setSettingDays] = React.useState([]);
    const [SettingMinute, setSettingMinute] = React.useState([]);


    //////////////////////////// Uygun zamanları hesapla////////////////////////////
    const getSettingBookingData = async (e) => {
        const docRef = doc(db, "profiles/" + props?.profileId + "/setting/", "0");
        //const docRef = doc(db, "profiles/kdMUqtMhpPc4Hujeu6qL/setting/", "0");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setSettingData(docSnap.data());
            setSettingBookingState(docSnap.data().state);
            setSettingTimes(docSnap.data().times);
            setSettingDays(docSnap.data().days);
            setSettingMinute(docSnap.data());
        } else {
            console.log("No such document!");
        }
    }

    getSettingBookingData();


    ///////////////////////// Alınmış randevuları derle ////////////////////////////
    const [bookingTime, setbookingTime] = React.useState([]);

    const BookingCollection = async (e) => {
        let slots = [];
        let start;
        let end;


        const collectionRefBooking = collection(db, 'booking')
        const qBooking = query(collectionRefBooking, where("profileId", "==", props?.profileId))
        const doc_refs = await getDocs(qBooking);
        doc_refs.forEach(doc => {
            start = doc.data().startDate;
            end = doc.data().endDate;
            slots.push(
                {
                    start,
                    end,
                }
            )
        });
        setbookingTime(slots);
    }

    useEffect(() => {
        BookingCollection();

    }, []);

    bookingTime.forEach(
        function (element) {
            const startTimeStamp = new Date(element.start.seconds * 1000);
            const startString = startTimeStamp.toISOString();
            const endTimeStamp = new Date(element.end.seconds * 1000);
            const endString = endTimeStamp.toISOString();


            unavailableTimeSlots.push( //////// diziye alınmış randevuları gönderiyorum...
                {
                    startTime: new Date(startString),
                    endTime: new Date(endString),
                }
            );
        }
    );


    /////////////////////////////////////////////////////////////////




    const minTime = Math.min(...SettingTimes);
    const maxTime = Math.max(...SettingTimes);
    const daysArrayLength = SettingDays.length;


    /////////////////// ISO 8601 STRING OLUŞTURUCU///////////////////

    /// MOMENT kütüphanesini kullandım //////
    const moment = require('moment');

    function getISO8601String(weekNumber, dayNumber, hour, minute, second) {

        const year = moment().year();

        const firstDayOfYear = moment([year]);

        moment.updateLocale('en', { week: { dow: 1 } });

        const firstDayOfWeek = firstDayOfYear.clone().isoWeek(weekNumber);

        const requestedDay = firstDayOfWeek.clone().add(dayNumber, 'days');

        const date = moment({
            year: year,
            month: requestedDay.month(),
            date: requestedDay.date(),
            hour: hour,
            minute: minute,
            second: second,
        });

        return date.toISOString();
    }
    //////////////////////////////////////////////////////////////////


    const currentDate = moment();
    let currentWeekNumber = currentDate.isoWeek(); /// geçerli hafta numarasını hesapladım


    //////RANDEVU SİSTEMİ KODU////////////////

    for (let z = 0; z < 4; z++) { ///// alınan uygunluk bilgisi 4 haftalık ekleniyor...


        for (let i = 0; i < daysArrayLength; i++) {


            const weekDay = parseInt(SettingDays[i]);
            const isoStartTime = getISO8601String(currentWeekNumber, weekDay, minTime, 0, 0);
            const isoEndTime = getISO8601String(currentWeekNumber, weekDay, maxTime, 0, 0);
            addAvailableSlot(isoStartTime, isoEndTime);



        }
        currentWeekNumber++;
    }


    function addAvailableSlot(start, end) {
        availableTimeSlots.push(
            {
                startTime: new Date(start),
                endTime: new Date(end),
            }
        );

    }


    const availableTimeSlotsLessUnavailableTimeSlots = timeSlotDifference(availableTimeSlots, unavailableTimeSlots);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
        return {
            startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)),
            endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(17, 0, 0, 0)),
        };
    });






    //////////////////////MEET///////////////////////////////////

    const [controllerMeetName, setControllerMeetName] = React.useState('');
    const hCsetControllerMeetName = (event) => {
        setControllerMeetName(event.target.value);
    };

    const [controllerMeetPhone, setControllerMeetPhone] = React.useState('');
    const hCsetControllerMeetPhone = (event) => {
        if (Array.from(event.target.value)[0] == "5") {
            setControllerMeetPhone("+90" + event.target.value);
        } else if (Array.from(event.target.value)[0] == "0") {
            setControllerMeetPhone("+9" + event.target.value);
        } else if (Array.from(event.target.value)[0] == "9") {
            setControllerMeetPhone("+" + event.target.value);
        } else {
            setControllerMeetPhone(event.target.value);
        }
    };

    const [controllerMeetMail, setControllerMeetMail] = React.useState('');
    const hCsetControllerMeetMail = (event) => {
        setControllerMeetMail(event.target.value);
    };

    const [controllerMeetSubject, setControllerMeetSubject] = React.useState('');
    const hCsetControllerMeetSubject = (event) => {
        setControllerMeetSubject(event.target.value);
    };

    const [controllerMeetDetail, setControllerMeetDetail] = React.useState('');
    const hCsetControllerMeetDetail = (event) => {
        setControllerMeetDetail(event.target.value);
    };

    const [controllerMeetStartDate, setControllerMeetStartDate] = React.useState('');
    const hCsetControllerMeetStartDate = (event) => {
        event.resetDate();
        event.resetSelectedTimeState();
        setControllerMeetStartDate(event.startTime);

        console.log("event.startTime: " + event.startTime);
    };






    const handleMeetSubmit = useCallback(async (e) => {
        console.log("controllerMeetStartDate: " + controllerMeetStartDate);
        const current = new Date();
        const datenow = `${current.getFullYear()}/${current.getMonth()}/${current.getDate()} ${current.getHours()}:${current.getMinutes()}`;
        const docRef = await addDoc(collection(db, 'booking'), {
            bookingId: "",
            userId: props?.userId,
            fullName: controllerMeetName,
            profileId: props?.profileId,
            cardId: props?.cardId,
            phone: controllerMeetPhone,
            mail: controllerMeetMail,
            placeAddress: "",
            subject: controllerMeetSubject,
            status: false,
            createDate: Timestamp.fromDate(new Date()),
            startDate: Timestamp.fromDate(new Date(controllerMeetStartDate)),
            endDate: Timestamp.fromDate(new Date(controllerMeetStartDate.setTime(controllerMeetStartDate.getTime() + (1 * parseInt(props?.bookingminute) * 60 * 1000)))),
            price: "",
            type: "",
            detail: controllerMeetDetail,
            state: true,
        });
        console.log("DocId: " + docRef.id);
        await updateDoc(doc(db, "booking", docRef.id), {
            bookingId: docRef.id
        });
        setActiveStep(1);
    });


    const handleAddEvent2Calendard = () => {
        const eventDateTime = new Date(Timestamp.fromDate(new Date(controllerMeetStartDate)));
        const eventTitle = props?.namesurname + " ile randevu";

        window.calendar.createEvent(eventDateTime, eventTitle);

        // Tarayıcıya bağlı olarak uygun takvim işlevini kullanın
        if (window.calendar) {
            window.calendar.createEvent(eventDateTime, eventTitle);
            // Yerel takvim işlevini çağırarak etkinliği takvime ekleyin
            console.log('Etkinlik takvime eklendi:', eventTitle, eventDateTime);
        } else {
            console.log('Takvim API bulunamadı');
        }
    };


    const handleAddEvent2Calendar = {
        title: props?.namesurname + " ile randevu",
        description: props?.namesurname + " ile görüşme talebi CardNero'dan alındı. Cevap bekleniyor.",
        startTime: "2023-10-07T10:30:00+3:00",
        endTime: "2023-10-07T11:30:00+3:00",
        location: "CardNero",
        attendees: [
            "Hello World <hello@world.com>",
            "Hey <hey@test.com>",
        ]
    }







    return (
        <div>

            {SettingBookingState ? (
                <div>
                    <Grid item xs={12} md={12}>
                        <div onClick={handleBookingOpen} style={{
                            color: 'white',
                            backgroundColor: '#2D2D2D',
                            padding: '15px 0',
                            borderRadius: '10px',
                            textAlign: 'center',
                        }}>
                            <p style={{ fontSize: "15pt", padding: '5px 0', }} >
                                {props?.countryCode === props?.countryTrCode ? (
                                    "Randevu Al"
                                ) : (
                                    "Make an Appointment"
                                )}
                            </p>

                        </div>
                    </Grid>
                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        open={bookingOpen}
                        //style={modalStyles}
                        onClose={handleBookingClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                TransitionComponent: Fade,
                            },

                        }}
                    >
                        <Fade in={bookingOpen}>
                            <Box sx={style}>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={10}>
                                        <Typography id="spring-modal-title" variant="h6" component="h2">
                                            {props?.countryCode === props?.countryTrCode ? (
                                                "Randevu Al"
                                            ) : (
                                                "Make an Appointment"
                                            )}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={2} sm={2} textAlign={"right"}>
                                        <Button onClick={handleBookingClose}>
                                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#2D2D2D', fontSize: "18pt", }} />
                                        </Button>
                                    </Grid>


                                    {activeStep === 0 ? (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12}>
                                                {props?.countryCode === props?.countryTrCode ? (
                                                    <ScheduleMeeting
                                                        borderRadius={10}
                                                        primaryColor="#3f5b85"
                                                        eventDurationInMinutes={parseInt(props?.bookingminute)}
                                                        availableTimeslots={availableTimeSlotsLessUnavailableTimeSlots}
                                                        defaultDate={controllerMeetStartDate}
                                                        selectedStartTime={controllerMeetStartDate}
                                                        onStartTimeSelect={hCsetControllerMeetStartDate}
                                                        //startTimeListStyle="scroll-list"
                                                        startTimeListStyle="grid"
                                                        lang_cancelButtonText="İptal"
                                                        lang_confirmButtonText="Seç"
                                                        lang_emptyListText="Uygun zaman yok"
                                                        lang_goToNextAvailableDayText="Sonraki uygun zaman"
                                                        lang_noFutureTimesText="Gelecek zaman yok"
                                                        lang_selectedButtonText="Seçildi:"
                                                        locale={tr}
                                                        format_nextFutureStartTimeAvailableFormatString="ccc, LLLL do"
                                                        format_selectedDateDayTitleFormatString=" do LLLL ccc"
                                                    />
                                                ) : (
                                                    <ScheduleMeeting
                                                        borderRadius={10}
                                                        primaryColor="#3f5b85"
                                                        eventDurationInMinutes={parseInt(props?.bookingminute)}
                                                        availableTimeslots={availableTimeSlotsLessUnavailableTimeSlots}
                                                        defaultDate={controllerMeetStartDate}
                                                        selectedStartTime={controllerMeetStartDate}
                                                        onStartTimeSelect={hCsetControllerMeetStartDate}
                                                        //startTimeListStyle="scroll-list"
                                                        startTimeListStyle="grid"
                                                        lang_cancelButtonText="Cancel"
                                                        lang_confirmButtonText="Select"
                                                        lang_emptyListText="No time available"
                                                        lang_goToNextAvailableDayText="Next available time"
                                                        lang_noFutureTimesText="No future time"
                                                        lang_selectedButtonText="Chosen:"
                                                        locale={enUS}
                                                        format_nextFutureStartTimeAvailableFormatString="ccc, LLLL do"
                                                        format_selectedDateDayTitleFormatString=" do LLLL ccc"
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="controllerMeetName"
                                                    name="Name"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Ad Soyad"
                                                    ) : (
                                                        "Name Surname"
                                                    )}
                                                    value={controllerMeetName}
                                                    onChange={hCsetControllerMeetName}
                                                    fullWidth
                                                    autoComplete="Name" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="controllerMeetPhone"
                                                    name="Phone"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Telefon"
                                                    ) : (
                                                        "Phone"
                                                    )}
                                                    value={controllerMeetPhone}
                                                    onChange={hCsetControllerMeetPhone}
                                                    fullWidth
                                                    autoComplete="Phone" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="controllerMeetMail"
                                                    name="Mail"
                                                    label="E-Mail"
                                                    value={controllerMeetMail}
                                                    onChange={hCsetControllerMeetMail}
                                                    fullWidth
                                                    autoComplete="Email" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    required
                                                    id="controllerMeetSubject"
                                                    name="Subject"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Konu"
                                                    ) : (
                                                        "Subject"
                                                    )}
                                                    value={controllerMeetSubject}
                                                    onChange={hCsetControllerMeetSubject}
                                                    fullWidth
                                                    autoComplete="Subject" />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    id="controllerMeetDetail"
                                                    name="Detail"
                                                    label={props?.countryCode === props?.countryTrCode ? (
                                                        "Detay"
                                                    ) : (
                                                        "Detail"
                                                    )}
                                                    value={controllerMeetDetail}
                                                    onChange={hCsetControllerMeetDetail}
                                                    maxRows={5}
                                                    minRows={5}
                                                    multiline
                                                    fullWidth
                                                    autoComplete="Detail" />

                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                < Button
                                                    variant="contained"
                                                    onClick={handleMeetSubmit}
                                                >{props?.countryCode === props?.countryTrCode ? (
                                                    "Gönder"
                                                ) : (
                                                    "Send"
                                                )}</Button>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12}>
                                                <center>
                                                    <br />
                                                    <Typography id="spring-modal-title" variant="h6" component="h3">
                                                        {props?.countryCode === props?.countryTrCode ? (
                                                            "Talebiniz alınmıştır."
                                                        ) : (
                                                            "Your request has been received."
                                                        )}
                                                    </Typography>
                                                    <Typography id="spring-modal-title" variant="h6" component="h3">
                                                        {props?.countryCode === props?.countryTrCode ? (
                                                            "En kısa sürede dönüş yapılacaktır."
                                                        ) : (
                                                            "Return will be made as soon as possible."
                                                        )}
                                                    </Typography>
                                                    <br /><br />

                                                    <ICalendarLink event={handleAddEvent2Calendar}>
                                                        Randevuyu Takvime Ekle
                                                    </ICalendarLink>
                                                    <br /><br />
                                                    <Grid item xs={12} sm={12}>
                                                        < Button
                                                            variant="contained"
                                                            onClick={handleBookingClose}
                                                        >{props?.countryCode === props?.countryTrCode ? (
                                                            "Kapat"
                                                        ) : (
                                                            "Close"
                                                        )}</Button>
                                                    </Grid>
                                                </center>
                                            </Grid>
                                        </React.Fragment>
                                    )}


                                </Grid>
                            </Box>
                        </Fade>
                    </Modal>
                </div >
            ) : (<div></div>)}
        </div >

    );
}

export default BookingModalCompanent;



const Fade = React.forwardRef(function Fade(props, ref) {

    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;

    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );

});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '5%',
    left: '5%',
    bottom: '5%',
    right: '5%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "hidden",
    overflowY: "scroll",
};