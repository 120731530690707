import * as React from "react";

function IconMapPin(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1112.728 0zM12 13a2 2 0 100-4 2 2 0 000 4z" />
        </svg>
    );
}

export default IconMapPin;