import React from 'react';
import '../../assets/styles/home.css';
import UserMiniCardCompanent from '../../components/cards/userMiniCardCompanent.js';

import { useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import { db } from '../../config/firebase';


const BusinessUsersCompanent = (props) => {

    useEffect(() => {
        getUsersInfo();
    }, []);

    const [profileData, setProfileData] = React.useState([]);


    const getUsersInfo = async (e) => {
        const docRefProfile = doc(db, "profiles", props.profilid);
        const docSnapProfile = await getDoc(docRefProfile);
        if (docSnapProfile.exists()) {
            console.log("Document data:", docSnapProfile.data());
            setProfileData(docSnapProfile.data());
            //Profile State 0 - Bu profil Kapalıdır
            var viewCountInt = docSnapProfile.data().viewCount + 1;
            await updateDoc(docRefProfile, { viewCount: viewCountInt });
        } else {
            console.log("No such document!");
        }
    }


    return (

        <UserMiniCardCompanent
            url={profileData?.url}
            picture={profileData?.picture}
            namesurname={profileData?.name + " " + profileData?.surName}
            position={profileData?.position}
        />

    );
}

export default BusinessUsersCompanent;



